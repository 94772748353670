import React, { useState, useEffect } from "react";
import { useEventID } from "wecall-config-lib";
import logo from "../assets/logo.png";
import mentioned from "../assets/Mentioned on.png";
import newslogo1 from "../assets/Forbes_logo 1.png";
import newslogo2 from "../assets/BBC_Logo_2021 1.png";
import newslogo3 from "../assets/cnn-logo 1.png";
import newslogo4 from "../assets/NewYorkTimes 1.png";
import newslogo5 from "../assets/ABC_News_logo_2021 1.png";
import newslogo6 from "../assets/Frame 39232.png";
import star from "../assets/star.png";
import { Link, Outlet } from "react-router-dom";
import testimonial from "../assets/testimonials (2).png";
import user1 from "../assets/user-1.jpeg";
import bg22 from "../assets/Ornament.png";
import InitialScripts from "../script/initialScripts";
import FloatingCard from "../floating-card";
import gasImage from "../assets/gas.png";
import groceryImage from "../assets/groceries.png";
import rentImage from "../assets/rent.png";
import shoppingImage from "../assets/cart.png";
import Accordion from "../component/Accordion";
export default function DefaultPage({ setNumber, cityStateResponse }) {
  const { state, city, zip } = cityStateResponse;
  const [isActive, setIsActive] = useState(false);
  const accordionData = [
    {
      id: "1",
      title: "1. Who can apply for the subsidy?",
      content: `Eligibility varies by program, typically based on income, employment status, or business size. Check our eligibility page for details.`,
    },
    {
      id: "2",
      title: "2. How do I apply?",
      content: `Fill out the application form on our website and submit the required documents. Visit our application guide for a step-by-step process.`,
    },
    {
      id: "3",
      title: "3. What documents are needed?",
      content: `Common documents include a government-issued ID, proof of income, and for businesses, proof of ownership and financial statements. See the full list on our documentation page.`,
    },
    {
      id: "4",
      title: "4. How long until I receive the subsidy?",
      content: `Processing usually takes 4-6 weeks. You can track your application status online or contact support for updates.`,
    },
  ];

  return (
    <>
      <InitialScripts setNumber={setNumber} />
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img src={logo}></img>
                    <h1>
                      Subsidy <span>One</span>
                    </h1>
                  </a>
                </div>
                <div className="logo-end">
                  <button>Claim Now</button>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
              </div>
            </div>
            <div className="bg_overlay">
              <div className="bg_1_overlay">
                <img src={bg22} alt="" />
              </div>
              <div className="bg_2_overlay">
                <div className="bg2_1"></div>
                <div className="bg2_2"></div>
                <div className="bg2_3"></div>
              </div>
              <div className="bg_3_overlay"></div>
            </div>
          </div>

          <div className="card-section">
            <div className="container">
              <div className="row">
                <div className="custom-container">
                  <div className="icon-pack">
                    <div className="headline-back">
                      <img src={mentioned}></img>
                    </div>
                    <div className="logo-icon-pack">
                      <img src={newslogo1}></img>
                      <img src={newslogo2}></img>
                      <img src={newslogo3}></img>
                      <img src={newslogo4}></img>
                      <img src={newslogo5}></img>
                      <img src={newslogo6}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section1">
            <div className="container">
              <div className="row">
                <div className="section-1-content">
                  <h2>
                    Less than 24 hours left to enroll in benefits in{" "}
                    {cityStateResponse.state}
                  </h2>
                  <p>
                    Thanks to a new government subsidiary program,{" "}
                    {cityStateResponse.state} residents could get $0 Free Health
                    Insurance and up to $1400* allowance card for the everyday
                    expense including No-Cost Vision & No-Cost Dental Benefits.
                  </p>
                </div>
                <div className="item-benefits">
                  <ul>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img
                            src={groceryImage}
                            className="cardimggrocery"
                          ></img>
                        </div>
                        <div className="benefitHeadline">
                          <h3>Groceries</h3>
                          <p>
                            You are entitled to a free flex card to buy healthy
                            food.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img src={rentImage} className="cardimgrent"></img>
                        </div>
                        <div className="benefitHeadline">
                          <h3>Rent</h3>
                          <p>
                            You are entitled to a free flex card to pay the
                            house rent.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img src={gasImage} className="cardimggas"></img>
                        </div>
                        <div className="benefitHeadline">
                          <h3>Gas</h3>
                          <p>
                            You are entitled to a free flex card to pay for gas.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img
                            src={shoppingImage}
                            className="cardimgshopping"
                          ></img>
                        </div>
                        <div className="benefitHeadline">
                          <h3>Shopping</h3>
                          <p>
                            You are entitled to a free flex card to pay for
                            other benefit programs.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="cta-holder">
                  <Link to={"income"} id="cta-click">
                    I Want My Card
                  </Link>
                  <div className="cta-para">
                    <span>37 Available Slots Left, Act Now!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section2">
            <div className="container">
              <div className="row">
                <div className="section2-headline-container">
                  <div className="section2-headline">
                    <h1>
                      Transforming Lives: The Impact of the Subsidy Program
                    </h1>
                    <p>
                      Dive into the remarkable journey of the Subsidy Program, a
                      pivotal initiative reshaping the financial landscape for
                      low-wage Americans. Witness the significant strides made
                      in providing essential support for groceries, rent, gas,
                      and bills. Here's a snapshot of the monumental impact and
                      ongoing progress:
                    </p>
                  </div>
                  <div className="section2-stat-counter">
                    <div className="row">
                      <div className="statCounter">
                        <div className="statCounterStats">
                          <h3>$43 Million</h3>
                        </div>
                        <div className="statCounterIntro">
                          <p>Subsidies Allocated</p>
                        </div>
                      </div>
                      <div className="statCounter">
                        <div className="statCounterStats">
                          <h3>250,000+</h3>
                        </div>
                        <div className="statCounterIntro">
                          <p>People Served</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="statCounter">
                        <div className="statCounterStats">
                          <h3>1 in 3</h3>
                        </div>
                        <div className="statCounterIntro">
                          <p>Population Qualifies</p>
                        </div>
                      </div>
                      <div className="statCounter">
                        <div className="statCounterStats">
                          <h3>482</h3>
                        </div>
                        <div className="statCounterIntro">
                          <p>Seats Remaining</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h1>Try Yourself Today!</h1>
                </div>
                <div className="testimonial-content-holder">
                  <p>
                    We strive to offer you the best service, as evidenced by our
                    Trustpilot star rating.
                  </p>
                  <img src={testimonial} alt="" />
                </div>
                <div className="testimonial-list">
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          I just received mine fast! They worked tirelessly to
                          secure the financial subsidy I needed, and their
                          dedication to customer satisfaction is unmatched.
                        </p>
                      </div>
                      <div className="testimonial-top">
                        <div className="star-rating">
                          <img src={star} alt="" />
                        </div>
                      </div>

                      <div className="testimonial-top">
                        <img src={user1} className="userimg"></img>
                        <h3>Jane Smith</h3>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          I just received mine fast! They worked tirelessly to
                          secure the financial subsidy I needed, and their
                          dedication to customer satisfaction is unmatched.
                        </p>
                      </div>
                      <div className="testimonial-top">
                        <div className="star-rating">
                          <img src={star} alt="" />
                        </div>
                      </div>

                      <div className="testimonial-top">
                        <img src={user1} className="userimg"></img>
                        <h3>Jane Smith</h3>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          I just received mine fast! They worked tirelessly to
                          secure the financial subsidy I needed, and their
                          dedication to customer satisfaction is unmatched.
                        </p>
                      </div>
                      <div className="testimonial-top">
                        <div className="star-rating">
                          <img src={star} alt="" />
                        </div>
                      </div>

                      <div className="testimonial-top">
                        <img src={user1} className="userimg"></img>
                        <h3>Jane Smith</h3>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          I just received mine fast! They worked tirelessly to
                          secure the financial subsidy I needed, and their
                          dedication to customer satisfaction is unmatched.
                        </p>
                      </div>
                      <div className="testimonial-top">
                        <div className="star-rating">
                          <img src={star} alt="" />
                        </div>
                      </div>

                      <div className="testimonial-top">
                        <img src={user1} className="userimg"></img>
                        <h3>Jane Smith</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section3">
            <div className="container">
              <div className="row">
                <div className="faq">
                  <h2>FAQs</h2>
                  <p>
                    Get quick answers to your most pressing questions about our
                    subsidy programs below. If you need more information, please
                    contact us.
                  </p>
                </div>

                <div className="accordion">
                  {accordionData.map(({ title, content, id }) => (
                    <Accordion key={id} title={title} content={content} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container top-footer">
              <div className="row">
                <div className="footer-content">
                  <p>
                    {window.domain_settings.websiteName} is not an insurance or
                    operating company but connects individuals with insurance
                    providers and other affiliates. Plans are insured or covered
                    by a Medicare Advantage organization with a Medicare
                    contract and/or a Medicare-approved Part D
                    sponsor.Enrollment in the plan depends on the plan’s
                    contract renewal with Medicare. Possible options include,
                    but are not limited to Major Medical Plans, Short Term
                    Plans, Christian Health Plans, Health Sharing Plans,
                    discount cards and Fixed Indemnity Plans.Descriptions are
                    for informational purposes only and subject to change.
                    {" " + window.domain_settings.websiteName} is not affiliated
                    with any insurance plan nor does it represent or endorse any
                    plan. We do not offer every plan available in your area. Any
                    information we provide is limited to those plans we do offer
                    in your area. Please contact Medicare.gov or 1–800 MEDICARE
                    to get information on all of your options. By using this
                    site, you acknowledge that you have read and agree to the
                    Terms of Service, and Privacy Policy. Not affiliated with
                    the U. S. government or federal Medicare program. A licensed
                    agent may contact you regarding this insurance-related
                    information.
                  </p>
                </div>
              </div>
              <hr />
            </div>

            <div className="container bottom-footer">
              <div className="row">
                <div className="footer-navigation">
                  <div className="footer-copyright">
                    <p>
                      © Copyright {window.domain_settings.websiteTitle} 2023
                    </p>
                  </div>
                  <div className="footer-navigation-wrapper">
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms of Use</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingCard />
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
