import React, { useState } from "react";
import { useEffect } from "react";
import "./index.scss";
import ChristieImage from "./christie.png";
import Kathryn from "./kathryn.png";
import Nancy from "./nancy.png";
import Nikki from "./nikki.png";
import Richard from "./richard.png";

const aca = [
  {
    name: "Velia E. Dunn",
    amount: "6400",
    image: ChristieImage,
  },
  {
    name: "Mary T. Pritts",
    amount: "6400",
    image: Kathryn,
  },
  {
    name: "Wanda D. Petersen",
    amount: "5600",
    image: Nancy,
  },
  {
    name: "Paul N. Parsons",
    amount: "6400",
    image: Nikki,
  },
  {
    name: "Peter M. Galles",
    amount: "5200",
    image: Richard,
  },
];
var locations = [
  "Kane, PA",
  "Detroit,	MI",
  "Norfolk, VA",
  "Sturgis, MI",
  "Kissimmee, FL",
  "Dallas, TX",
  "Dover, DE",
  "Hilliard, OH",
  "Tyler, TX",
  "Largo, FL",
  "Clinton, TN",
  "El Paso, TX",
  "Crockett, TX",
  "Hutchinson, KS",
  "Mankato, MN",
  "Sparks, NV",
  "Nashua, NH",
  "Milbank, SD",
  "Wichita, KS",
  "Cape Coral, FL",
  "Lorain, OH",
  "Eagle Pass, TX",
  "Minneapolis, MN",
  "Biddeford, ME",
  "Merced, CA",
  "New Orleans, LA",
  "Oakland, CA",
  "Bay City, TX",
  "Longview, TX",
  "Arkansas City, KS",
  "Neosho, MO",
  "Bells, TN",
  "Polson, MT",
  "Wasilla, AK",
  "Dale, TX",
  "Deltona, FL",
  "Gainesville, FL",
  "Oroville, CA",
  "Reserve, LA",
  "Waipahu, HI",
  "Norfolk, VA",
  "Moss Point, MS",
  "Washington, DC",
  "Staunton, VA",
  "Front Royal, VA",
  "Tampa, FL",
  "San Pedro, CA",
  "Pasadena, CA",
  "Hillsville, VA",
  "Quantico, VA",
  "El Campo, TX",
  "Harvey, LA",
  "West Plains, MO",
  "Sonora, CA",
  "Denver, CO",
  "Hamilton, MS",
  "New Town, ND",
  "Ariton, AL",
  "Wolford, VA",
  "Ballinger, TX",
  "Elmendorf, TX",
  "Topeka, KS",
  "Salem, OR",
  "Portsmouth, VA",
  "Saint Paul, MN",
  "Stockdale, TX",
  "Petersburg, VA",
  "Wylie, TX",
  "Houston, TX",
  "Eau Claire, WI",
  "Knoxville, TN",
  "Auburn, IL",
  "Houma, LA",
  "Topeka, KS",
  "Mitchell, SD",
  "Silsbee, TX",
  "Fort Worth, TX",
  "Niota, TN",
  "Ravenna, OH",
  "Porter, TX",
  "Katy, TX",
  "Chicago, IL",
  "Berne, IN",
  "Wichita, KS",
  "Los Angeles, CA",
  "Hondo, NM",
  "Chefornak, AK",
  "Akron, OH",
  "Victorville, CA",
  "Arabi, LA",
  "Suring, WI",
];

const FloatingCard = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState("");

  const getData = () => {
    const vertical = window.domain_settings.vertical;
    return aca;
  };

  var products = getData();

  const getProduct = () => {
    const num = Math.floor(Math.random() * products.length);
    const nameElement = products[num].name;
    const imageElement = products[num].image;
    const amountElement = products[num].amount;

    setSelectedProduct(products[num]);

    const userElement = document.querySelector(".user_name");
    userElement.textContent = nameElement;

    const productImageElement = document.querySelector(".product_image");
    productImageElement.src = imageElement;

    const amountElements = document.querySelector(".amount");
    amountElements.textContent = amountElement;
  };

  const getLocation = () => {
    const num = Math.floor(Math.random() * locations.length);
    setSelectedLocation(locations[num]);

    const locationElement = document.querySelector(".location");
    locationElement.textContent = locations[num];
  };

  const getTime = () => {
    const type = ["seconds", "minutes"];
    const typeNo = Math.floor(Math.random() * type.length);
    const time = Math.round(Math.random() * 60) + 1;

    const numberElement = document.querySelector(".number");
    const typeElement = document.querySelector(".type");

    numberElement.textContent = time;
    typeElement.textContent = type[typeNo];
  };

  useEffect(() => {
    getProduct();
    getLocation();
    getTime();

    const changeNotification = () => {
      showNotification();
      setTimeout(() => {
        hideNotification();
      }, 6000);
    };

    const showNotification = () => {
      const addElement = document.getElementById("fomo");
      addElement.classList.add("is-visible");
    };

    const hideNotification = () => {
      const removeElement = document.getElementById("fomo");
      removeElement.classList.remove("is-visible");
      setTimeout(() => {
        getProduct();
        getLocation();
        getTime();
      }, 500);
    };

    const loop = () => {
      const rand = Math.round(Math.random() * 5000) + 8000;
      setTimeout(() => {
        changeNotification();
        loop();
      }, rand);
    };

    loop();
  }, []);

  return (
    <>
      <div id="fomo">
        <img
          src={selectedProduct ? selectedProduct.image : ""}
          className="product_image"
          alt="Product"
        />
        <div className="wrapper">
          <div className="buyer">
            <span className="user_name">
              {selectedProduct ? selectedProduct.name : ""}
            </span>{" "}
            from <span className="location">{selectedLocation}</span> Qualified
            For ${" "}
            <span className="amount">
              {selectedProduct ? selectedProduct.amount : ""}
            </span>{" "}
            Subsidy Card.
          </div>
          <div className="time">
            <span className="number"></span>
            <span className="type"></span> ago
          </div>
        </div>
      </div>
    </>
  );
};

export default FloatingCard;
