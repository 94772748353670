import { useInitRingba } from "wecall-config-lib";

export default function GetRingbaNumber() {
  const ringbaKey = window.domain_settings.rinbaUser;

  useInitRingba({
    ringbaKey,
  });

  return <></>;
}
